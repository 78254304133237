<template>
  <modal-dialog
    :show="isActive"
    heading="Select your new refund option"
    @close="closeModal"
  >
    <div
      class="edit-refund-method-modal__select-refund-container"
    >
      <radio-input
        v-if="props.available.gift"
        v-model="selected"
        class="edit-refund-method-modal__input"
        :value="returnCreditTypes.GIFT"
      >
        <base-card
          ref="storeCredit"
          class="edit-refund-method-modal__card"
          tag="span"
          :selected="selected === returnCreditTypes.GIFT"
        >
          <span class="edit-refund-method-modal__card-content">
            <base-icon
              class="edit-refund-method-modal__card-icon"
              name="gift"
            />
            <span class="edit-refund-method-modal__label">
              <base-text
                class="edit-refund-method-modal__heading"
                type="heading"
              >
                <render-content>
                  {{ $content.moduleCreditSelector.storeCreditHeading }}
                </render-content>
              </base-text>
              <base-text
                class="edit-refund-method-modal__body"
                type="body-2"
              >
                <render-content>
                  {{ $content.moduleCreditSelector.storeCreditCopy }}
                </render-content>
              </base-text>
            </span>
          </span>
        </base-card>
      </radio-input>
      <radio-input
        v-if="props.available.refund"
        v-model="selected"
        class="edit-refund-method-modal__input"
        :value="returnCreditTypes.REFUND"
      >
        <base-card
          ref="originalCredit"
          class="edit-refund-method-modal__card"
          tag="span"
          :selected="selected === returnCreditTypes.REFUND"
        >
          <span class="edit-refund-method-modal__card-content">
            <base-icon
              class="edit-refund-method-modal__card-icon"
              name="credit-card"
            />
            <span class="edit-refund-method-modal__label">
              <base-text
                class="edit-refund-method-modal__heading"
                type="heading"
              >
                <render-content>
                  {{ $content.moduleCreditSelector.ogPaymentHeading }}
                </render-content>
              </base-text>
              <base-text
                class="edit-refund-method-modal__payment-copy"
                type="body-2"
              >
                <render-content>
                  {{ $content.moduleCreditSelector.ogPaymentCopyPerProduct }}
                </render-content>
              </base-text>
            </span>
          </span>
        </base-card>
      </radio-input>
      <base-button
        class="edit-refund-method-modal__save"
        :disabled="selected === null || selected === props.currentRefundType"
        :activated="saveButtonLoading"
        @click.prevent="handleSelectNewRefundOption"
      >
        Save
      </base-button>
      <edit-return-confirmation-modal
        v-if="showConfirmationModal"
        :is-modal-active="showConfirmationModal"
        heading="Your refund method has been updated"
        message=""
        confirm-button-text="I’m done. View my return."
        :is-destroy="false"
        @close="showConfirmationModal = false"
        @confirmed="closeModal(true)"
        @denied="closeModal"
      />
    </div>
  </modal-dialog>
</template>
      
<script setup>
import {
  BaseCard,
  BaseIcon,
  BaseText,
  RadioInput,
  BaseButton
} from '@loophq/design-system';
import { ref, computed } from 'vue';
import ModalDialog from '@/components/layout/Modal';
import Returns from '@/js/controllers/return';
import { returnCreditTypes } from '@/js/constants/returns';
import EditReturnConfirmationModal from '@/views/StatusPage/EditReturnConfirmationModal.vue';
    
const props = defineProps({
  returnKey: {
    type: Number,
    required: true
  },
  available: {
    type: Object,
    required: true
  },
  currentRefundType: {
    type: [String, null],
    required: true
  },
});
    
const emit = defineEmits(['close', 'cancel', 'update']);
    

const active = ref(true);
const selected=ref(props.currentRefundType);
const saveButtonLoading = ref(false);
const showConfirmationModal = ref(false);
    
const isActive = computed(() => {
  return active.value;
});
    
const closeModal = (refreshPage = false) => {
  showConfirmationModal.value = false;
  active.value = false;
  emit('close', refreshPage);
};

const handleSelectNewRefundOption = async () => { 
  saveButtonLoading.value = true; 
  await Returns.changeRefundMethod(props.returnKey);
  saveButtonLoading.value = false;
  showConfirmationModal.value = true;
};
    
</script>

<style lang="scss" scoped>
$block: '.edit-refund-method-modal';
#{$block} {
  &__select-refund-container {
    height: 75vh;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
  }

  &__card {
    padding: var(--spacing-300) var(--spacing-300);
    box-shadow: none;
    transition: box-shadow var(--transition-300);
    width: 100%;

    &:hover:not(.selected),
    &:focus:not(.selected) {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 12%), 0 6px 12px rgba(0, 0, 0, 8%);
    }

    #{$block}__heading,
    #{$block}__card-icon {
      flex-shrink: 0;
      color: var(--body-color);
    }
  }
}
</style>
      
    
  
