import axios from 'axios';
import store from '@/store';
import changeCase from 'change-object-case';
import { merge } from 'lodash';
import { logError } from '../helpers/errors';
changeCase.options = { recursive: true, arrayRecursive: true };

// We need to grab this at runtime because this is not available when the code is first run
const getId = () => store.state.shopContents.id;

// This should go away eventually, but for now we need to map old data to new payloads
/**
 *
 * @param {object} order - A standard order object
 * @param {number} order.id - The loop id for this order
 * @param {object} order.line_items - An object of line items, from the BE
 * @param {array} order.cart - An array of variant objects
 */
const mapTotalsPayload = (order) => {
  const { creditType, id, line_items, cart, cart_id } = order;
  return {
    id,
    creditType,
    returnMethod: order.returnMethod,
    line_items: Object.values(line_items ?? {})
      .filter(item => !!item.resolution)
      .map(({ id, resolution, product, outcome, outcomesSetByWorkflow }) => {
        return {
          id,
          resolution,
          ...(product ? { product } : {}),
          outcome,
          outcomesSetByWorkflow,
        };
      }),
    cart: (cart ?? []).map(({ id, variant_id }) => {
      return {
        id: id ? id : variant_id,
      };
    }),
    cart_id,
    hasShopLaterOffer: store.state.return.hasShopLaterOffer || false,
  };
};

const mapFeesPayload = (order) => {
  return {
    id: order.id,
    gift: order.gift,
    currency: order.currency,
    creditType: order.creditType,
    returnMethod: order.returnMethod,
    address: {
      id: order.address.id,
    },
    customer: {
      id: order.customer.id,
      email: order.customer.email
    },
    return_policy: {
      id: order.return_policy.id
    },
    line_items: Object.values(order.line_items ?? {})
      .map((lineitem) => {
        const newVariant = lineitem.new_variant ? {
          id: lineitem.new_variant.id
        } : null;
        const reason = lineitem?.reason ? {
          id: lineitem?.reason?.reason_id
        } : null;
        const inputKeys = Object.keys(lineitem).filter(key => key.includes('user_input'));
        const userInputs = inputKeys.reduce((obj, input) => {
          return {
            ...obj,
            [input]: lineitem[input],
          };
        }, {});
        return {
          id: lineitem.id,
          returnType: lineitem.returnType,
          outcome: lineitem.outcome,
          outcomesSetByWorkflow: lineitem.outcomesSetByWorkflow,
          productId: lineitem.product_id,
          variantId: lineitem.variant_id,
          exchangeVariant: newVariant,
          reason,
          userInputs,
        };
      }),
  };
};

/**
 * Takes a product object and strips out the default variant title
 * @param {Object} product - A product object
 * @returns {Object} A product object with no default variant title
 */
const replaceDefaultTitle = (product) => {
  return {
    ...product,
    variantTitle: product.variantTitle === 'Default Title' ? '' : product.variantTitle
  };
};

export default {
  mapTotalsPayload,
  mapFeesPayload,
  /**
	 *
	 * @param {object} data - The contents of the return vuex module, orderId, and fees
	 */
  async getTotals({ lineItems, newItems, ...data }) {
    if (!lineItems?.length) {
      return Promise.resolve({
        errors: [`Can't request totals, no line items selected`],
        newTotals: null,
        receipt: null
      });
    }

    const shopId = getId();

    const cloneLineItems = lineItems.map(item => {
      return {
        ...item,
        userInputs: item.userInputs ? JSON.stringify(item.userInputs) : null
      };
    });

    const payload = changeCase.toCamel({
      ...data,
      lineItems: cloneLineItems,
      cart: newItems
    });

    payload.lineItems.forEach(item => {
      if (item.userInputs) {
        item.userInputs = JSON.parse(item.userInputs);
      }
    });

    const res = await axios
      .post(`api/v1/${shopId}/return/totals`, payload);
    return changeCase.toCamel(res.data);
  },
  /**
   *
   * @param {object} data - The contents of the return vuex module, orderId, and fees
   */
  async submit({ newItems, lineItems, ...data }) {
    const shopId = getId();

    const cloneLineItems = lineItems.map(item => {
      return {
        ...item,
        userInputs: item.userInputs ? JSON.stringify(item.userInputs) : null,
        outcome: [], // let backend workflow evaluation determine outcome
      };
    });

    const payload = changeCase.toCamel({
      ...data,
      lineItems: cloneLineItems,
      cart: newItems,
      totals: store.state.totals.totals,
    });

    payload.lineItems.forEach(item => {
      if (item?.userInputs) {
        item.userInputs = JSON.parse(item.userInputs);
      }
    });

    const res = await axios.post(`api/v1/${shopId}/return`, payload);
    return changeCase.toCamel(res.data);
  },
  async update(returnKey, shopLaterUuid, { newItems, lineItems, ...data }) {
    const shopId = getId();

    const cloneLineItems = lineItems.map(item => {
      return {
        ...item,
        userInputs: item.userInputs ? JSON.stringify(item.userInputs) : null
      };
    });

    const payload = changeCase.toCamel({
      ...data,
      lineItems: cloneLineItems,
      cart: newItems,
    });

    payload.lineItems.forEach(item => {
      if (item.userInputs) {
        item.userInputs = JSON.parse(item.userInputs);
      }
    });

    const res = await axios.post(`api/v1/${shopId}/return/${returnKey}/shoplater/${shopLaterUuid}/shop`, payload);
    return changeCase.toCamel(res.data);
  },
  getReturnMethod(returnKey) {
    const shopId = getId();
    return axios.get(`api/v1/${shopId}/return/${returnKey}/return-method`);
  },
  getReturnMethodState(returnKey) {
    const shopId = getId();
    return axios.get(`api/v1/${shopId}/return/${returnKey}/return-method/state`);
  },
  get(returnKey) {
    if (!returnKey || typeof returnKey !== 'string') {
      return logError('Return.get() requires a hash to fetch a return.');
    }
    const shopId = getId();
    const baseUrl = `api/v1/${shopId}/return/${returnKey}`;

    return axios.all([
      axios.get(`${baseUrl}/outcomes`),
      axios.get(`${baseUrl}/shipping`),
      axios.get(`${baseUrl}/customer`),
      axios.get(`${baseUrl}/receipt`),
      axios.get(`${baseUrl}/related`),
      axios.get(`${baseUrl}/returnlocations`)
        .then((res) => {
          return {
            ...res,
            data: {
              locations: res.data
            }
          };
        }),
      axios.get(`${baseUrl}/return-method`)
        .then((res) => {
          return {
            ...res,
            data: {
              returnMethod: res.data || null, // no content defaults to empty string
            }
          };
        })
        .catch((err) => {
          logError(err);
          return {
            data: {
              errors: {
                returnMethod: err.response?.data
              }
            }
          };
        })
    ])
      .then(axios.spread((...responses) => {
        let mappedResponse = {};
        responses.map(resp => {
          if (resp.data) {
            const data = changeCase.toCamel(resp.data);
            const itemKeys = ['exchanges', 'lineItems'];
            itemKeys.forEach((item) => {
              if (data[item] && Array.isArray(data[item])) {
                data[item] = data[item].map(replaceDefaultTitle);
              }
            });
            mappedResponse = merge(data, mappedResponse);
          }
        });

        const toReturn = mappedResponse.lineItems.filter((item) => item.outcome !== 'keep');
        const toKeep = mappedResponse.lineItems.filter((item) => item.outcome === 'keep');
        mappedResponse.toReturn = toReturn;
        mappedResponse.toKeep = toKeep;

        return mappedResponse;
      }));
  },
  updateCustomerAddress(returnId, customer) {
    if (!returnId || !customer || typeof customer !== 'object') {
      return console.error(`Return.updateCustomer() requires a customer object as input.`);
    }

    const shopId = getId();
    const request = changeCase.snakeKeys(customer);
    request.address1 = customer.address1;
    request.address2 = customer.address2;
    return axios
      .put(`api/v1/${shopId}/return/${returnId}/address`, request)
      .then((res) => {
        if (res.data === true) {
          return customer;
        }

        return res;
      });
  },
  /**
     * updatePrintLabel
     * A call to increase the printed_count by 1
     *
     * @param {string} returnKey - A uuid for a return
     */
  updatePrintLabelCount(returnKey) {
    const shopId = getId();
    return axios
      .post(`api/v1/${shopId}/return/${returnKey}/shipping/print-count`)
      .then(res => {
        return res;
      });
  },
  /**
     * Marks a return as being returned in store
     * @param {string} returnKey - A uuid for a return
     * @returns {boolean}
     */
  markInStore(returnKey) {
    const shopId = getId();
    return axios
      .post(`api/v1/${shopId}/return/${returnKey}/returninstore`)
      .then((res) => {
        return res.data;
      });
  },
  /**
   * printPackingSlip
   * A call to return packing slip pdf
   *
   * @param {string} returnKey - A uuid for a return
   */
  async printPackingSlip(returnKey) {
    const shopId = getId();
    return await axios
      .get(`api/v1/${shopId}/return/${returnKey}/packing-slip`, {
        responseType: 'blob'
      })
      .then(res => {
        return res;
      });
  },
  /**
   * acceptShopLater
   * A call to accept shop later
   *
   * @param {string} returnKey - uuid for a return
   * @param {string} shopLaterUUID - uuid for a shop later offer
   */
  acceptShopLater(returnKey, shopLaterUUID) {
    const shopId = getId();
    return axios
      .post(`api/v1/${shopId}/return/${returnKey}/shoplater/${shopLaterUUID}`)
      .then(res => {
        return changeCase.toCamel(res.data);
      })
      .catch((err) => {
        console.error(`Failed to accept shop later offer, err: ${err}`);
      });
  },
  getEditReturnOptions(returnKey) {
    const shopId = getId();
    return axios
      .get(`api/v1/${shopId}/return/${returnKey}/editability`)
      .then(res => {
        return changeCase.toCamel(res.data);
      })
      .catch((err) => {
        console.error(`Failed to get edit return options, err: ${err}`);
      });
  },
  cancelReturn(returnKey) {
    const shopId = getId();
    return axios
      .delete(`api/v1/${shopId}/return/${returnKey}/edit/cancel`)
      .then(res => {
        return changeCase.toCamel(res.data);
      })
      .catch((err) => {
        console.error(`Failed to cancel return, err: ${err}`);
      });
  },
  updateExchangeVariant(returnKey, exchangeId, variantId) {
    const shopId = getId();
    const payload = { variantId };
    return axios
      .patch(`api/v1/${shopId}/return/${returnKey}/exchange/${exchangeId}/edit/exchangeVariant`, payload)
      .then(res => {
        return changeCase.toCamel(res.data);
      })
      .catch((err) => {
        console.error(`Failed to update exchange variant, err: ${err}`);
      });
  },
  changeRefundMethod(returnKey) {
    const shopId = getId();
    return axios
      .post(`api/v1/${shopId}/return/${returnKey}/edit/refundMethod`)
      .then(res => {
        return changeCase.toCamel(res.data);
      })
      .catch((err) => {
        console.error(`Failed to change refund method, err: ${err}`);
      });
  },
  changeReturnMethod(returnKey, returnMethod) {
    const shopId = getId();
    const payload = { returnMethod };
    return axios
      .post(`api/v1/${shopId}/return/${returnKey}/edit/returnMethod`, payload)
      .then(res => {
        return changeCase.toCamel(res.data);
      })
      .catch((err) => {
        console.error(`Failed to change return method, err: ${err}`);
      });
  },
};
