<template>
    <modal-dialog
      :show="isModalActive"
      heading="Select desired return method"
      @close="handleClose"
    >
      <div
        class="edit-return-modal__return-method-action-container"
      >
        <radio-input
          v-for="(option) in returnMethodOptions"
          :key="option.id"
          :modelValue="selected"
          :value="option.selected"
          name="return-method"
          class="return-method__radio-input"
          @update:modelValue="returnMethodSelected(option.id)"
        >
          <base-card
            class="return-method__base-card"
            tag="span"
            :selected="option.selected"
          >
            <img
              :src="option.image"
              class="return-method__image"
            />
            <base-text type='heading'>
              {{ option.display }}
            </base-text>
          </base-card>
        </radio-input>

        <base-button
          v-if="showSave"
          variant="primary"
          class="edit-return-modal__next-button"
          :activated="loading"
          @click="moveToConfirm"
        >Save</base-button>
      </div>
    </modal-dialog>
</template>
<script setup>
import ModalDialog from '@/components/layout/Modal';
import { BaseCard, RadioInput, BaseButton, BaseText } from '@loophq/design-system';
import Returns from '@/js/controllers/return';
import { ref } from 'vue';

const props = defineProps({
  isModalActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  returnMethodOptions: {
    type: Array,
    required: true
  },
  returnKey: {
    type: String,
    required: true
  }
});

const showSave = ref(false);
const loading = ref(false);

const emit = defineEmits(['close', 'returnMethodSelected']);

const handleClose = () => {
  emit('close');
};

const returnMethodSelected = (selectedId) => {
  const selection = props.returnMethodOptions.find(option => option.id === selectedId);
  emit('returnMethodSelected', selection);
  showSave.value = true;
};

const moveToConfirm = () => {
  loading.value = true;
  const selectedReturnMethod = props.returnMethodOptions.find(option => option.selected);
  Returns.changeReturnMethod(props.returnKey, selectedReturnMethod.name);
  loading.value = false;
  emit('close', selectedReturnMethod);
};
</script>
<style scoped>
  .edit-return-modal__return-method-action-container {
    height: 75vh;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
  }
  .return-method__radio-input {
    padding: var(--spacing-400);
    width: 100%;
    border-bottom: 1px solid var(--grey-200);
  }
  .selected {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 10%);
  }
  .return-method__base-card {
    width: 100%;
    padding: var(--spacing-300) var(--spacing-300);
    box-shadow: none;
    transition: box-shadow var(--transition-300);
    flex-direction: row;
    align-items: center;
    justify-content: left;
  }
  .return-method__base-card:hover:not(.selected),
  .return-method__base-card:focus:not(.selected) {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 12%), 0 6px 12px rgba(0, 0, 0, 8%);
  }
  .edit-return-modal__next-button {
    margin-top: var(--spacing-400);
  }
  .return-method__image {
    margin-right: var(--spacing-200);
  }
</style>