<template>
  <modal-dialog
    :show="isModalActive"
    :heading="heading"
    @close="handleClose"
  >
    <div
      class="edit-return-modal__confirmation-action-container"
    >
      <base-text>
        {{ message }}
      </base-text>
      <div class="edit-return-modal__confirmation-button-container">
        <base-button
          variant="primary"
          class="confirm-edit-button"
          :activated="confirmButtonLoading"
          :class="props.isDestroy ? 'destroy' : ''"
          @click="confirmed"
        >
          {{ confirmButtonText }}
        </base-button>
        <base-button
          type="secondary"
          class="deny-edit-button"
          @click="denied"
        >
          <span class="deny-edit-button__text">
            <base-text 
              class="deny-edit-button__heading"
              tag="p"
              type="heading"
            >
              Manage your return
            </base-text>
            <base-text 
              class="deny-edit-button__subheading"
              tag="p"
              type="body-2"
            >
              Make an update or cancel your return
            </base-text>
          </span>
        </base-button>
      </div>
    </div>
  </modal-dialog>
</template>
<script setup>
import { ref } from 'vue';
import { BaseText, BaseButton } from '@loophq/design-system';
import ModalDialog from '@/components/layout/Modal';

const props = defineProps({
  isModalActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  heading: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  confirmButtonText: {
    type: String,
    required: true,
  },
  isDestroy: {
    type: Boolean,
    required: true,
    default: false
  }
});

const emit = defineEmits(['close', 'confirmed', 'denied']);

const confirmButtonLoading = ref(false);

const handleClose = () => {
  // add 'closing class to the modal-dialog element
  const modalDialog = document.querySelector('.modal-dialog');
  modalDialog.classList.add('closing');

  setTimeout(() => {
    modalDialog.classList.remove('closing');
    emit('close');
  }, 300);
};

const confirmed = () => {
  confirmButtonLoading.value = true;
  emit('confirmed');
};

const denied = () => {
  emit('denied');
};
</script>
<style scoped>
  /* TODO: transition away from modal in pleasing way, these transitions arent working*/
  .edit-return-modal__confirmation-action-container {
    height: 75vh;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-600);
  }

  .edit-return-modal__confirmation-button-container {
    margin-top: var(--spacing-400);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-400);
    width: 100%;
  }

  .confirm-edit-button
  .deny-edit-button {
    width: 100%;
    margin-top: 1rem;
  }

  .confirm-edit-button.destroy {
    &.primary {
      background: var(--color-red-600);
    }
  }

  .closing {
    transform: scale(0.95);
    opacity: 0;
  }

  .deny-edit-button__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      p {
        text-align: center;
        margin-top: var(--spacing-200);
      }
    }
</style>
