<template>
  <modal-dialog
    :show="isActive"
    heading="Select a new product"
    @close="closeModal"
  >
    <div
      v-if="true"
      class="edit-exchange-variant-modal__select-product-container"
    >
      <div
        v-for="(item, index) in mappedVariants"
        :key="index"
        class="edit-exchange-variant-modal__select-product"
      >
        <product-card
          :product="item"
          size="small"
          :selected="exchangeTitle === item.variant_title"
          @click="selectNewExchangeVariant(item)"
        />
      </div>
    </div>
    <base-button
      class="edit-exchange-variants-modal__save"
      :disabled="exchangeTitle === null"
      :activated="saveButtonLoading"
      @click.prevent="confirmNewExchangeVariant(exchangeTitle)"
    >
      Confirm
    </base-button>
    <edit-return-confirmation-modal
      v-if="showConfirmationModal"
      :is-modal-active="showConfirmationModal"
      heading="Your exchange item has been updated"
      message=""
      confirm-button-text="I’m done. View my return."
      :is-destroy="false"
      @close="showConfirmationModal = false"
      @confirmed="closeModal(true)"
      @denied="closeModal"
    />
  </modal-dialog>
</template>
      
<script setup>
import {
  ProductCard,
  BaseButton
} from '@loophq/design-system';
import { ref, computed } from 'vue';
import ModalDialog from '@/components/layout/Modal';
import Returns from '@/js/controllers/return';
import EditReturnConfirmationModal from '@/views/StatusPage/EditReturnConfirmationModal.vue';
    
const props = defineProps({
  // Using exchange title here since we don't easily have access to the return 
  // variantId. This should be updated to use id in the future.
  exchangeTitle: {
    type: String,
    required: true
  },
  variants: {
    type: Array,
    required: true
  },
  returnKey: {
    type: Number,
    required: true
  },
  exchangeId: {
    type: Number,
    required: true
  },
});
    
const emit = defineEmits(['close', 'update']);
    
    
const active = ref(true);
const saveButtonLoading = ref(false);
const showConfirmationModal = ref(false);
    
const isActive = computed(() => {
  return active.value;
});

const mappedVariants = computed(() => {
  return props.variants.map((item) => {
    return {
      ...item,
      variants: item.variant_title,
      price: null
    };
  });
});
    
const closeModal = (refreshPage = false) => {
  showConfirmationModal.value = false;
  active.value = false;
  emit('close', refreshPage);
};

const selectNewExchangeVariant = async (item) => {
  emit('update', item.variant_title);
};

const confirmNewExchangeVariant = async (item) => {
  saveButtonLoading.value = true;
  const variantId = props.variants.find(variant => variant.variant_title === item).id;
  
  await Returns.updateExchangeVariant(props.returnKey, props.exchangeId, variantId);

  saveButtonLoading.value = false;
  showConfirmationModal.value = true;
};
    
</script>

<style lang="scss" scoped>
$block: '.edit-exchange-variant-modal';
#{$block} {
  &__select-product-container {
    height: 75vh;
  }

  &__select-product {
    margin-top: var(--spacing-200);
  }
}
</style>
      
    
  
