<template>
  <modal-dialog
    :show="isActive"
    heading="Select an exchange item to update"
    @close="handleClose"
  >
    <div
      class="edit-exchange-items-modal__select-product-container"
    >
      <div
        v-for="(item, index) in mappedExchangeItems"
        :key="index"
        class="edit-exchange-items-modal__select-product"
      >
        <product-card
          :product="item"
          size="small"
          :selected="selectedItem === item.productId"
          @click="handleSelectProduct(item)"
        />
      </div>
    </div>
    <base-button
      class="edit-exchange-items-modal__save"
      :disabled="selectedItem === null"
      @click.prevent="showEditExchangeVariantModal = true;"
    >
      Confirm
    </base-button>
    <edit-exchange-variant-modal
      v-if="showEditExchangeVariantModal"
      :variants="props.variants[selectedItem]"
      :returnKey="props.returnKey"
      :exchangeId="props.exchangeItems.find(item => item.productId === selectedItem).id"
      :exchangeTitle="selectedVariant ? selectedVariant : props.exchangeItems.find(item => item.productId === selectedItem).variantTitle"
      @update="handleUpdateSelectedVariant"
      @close="handleEditExchangeVariantModalClose"
    />
  </modal-dialog>
</template>
    
<script setup>
import {
  ProductCard,
  BaseButton
} from '@loophq/design-system';
import { ref, computed } from 'vue';
import ModalDialog from '@/components/layout/Modal';
import EditExchangeVariantModal from '@/views/StatusPage/EditExchangeVariantModal';
  
const props = defineProps({
  exchangeItems: {
    type: Array,
    required: true
  },
  variants: {
    type: Object,
    required: true
  },
  returnKey: {
    type: String,
    required: true
  }
});
  
const emit = defineEmits(['close', 'cancel', 'update']);
  
  
const active = ref(true);
const selectedItem = ref(null);
const selectedVariant = ref(null);
const showEditExchangeVariantModal = ref(false);
  
const isActive = computed(() => {
  return active.value;
});

const mappedExchangeItems = computed(() => {
  return props.exchangeItems.map((item) => {
    return {
      ...item,
      variants: item.variantTitle,
    };
  });
});
  
const handleClose = () => {
  active.value = false;
  emit('close');
};

const handleSelectProduct = (item) => {
  selectedItem.value === item.productId ? selectedItem.value = null : selectedItem.value = item.productId;
};

const handleEditExchangeVariantModalClose = (refreshPage = false) => {
  showEditExchangeVariantModal.value = false;
  selectedVariant.value = null;
  emit('close', refreshPage);
};

const handleUpdateSelectedVariant = (variantTitle) => {
  selectedVariant.value = variantTitle;
};
  
</script>
    
<style lang="scss" scoped>
    $block: '.edit-exchange-items-modal';
    #{$block} {
      &__select-product-container {
        height: 75vh;
      }
    }
</style>
    
  
