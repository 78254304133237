<template>
  <modal-dialog
    :show="isActive"
    heading="What would you like to change?"
    @close="closeModal"
  >
    <div
      class="edit-return-modal__action-container"
    >
      <base-button
        v-if="props.editOptions.address"
        class="edit-return-modal__address"
        type="secondary"
        :activated="addressButtonLoading"
        @click="handleUpdateAddress"
      >
        Exchange address
      </base-button>

      <base-button
        v-if="props.editOptions.returnMethod"
        class="edit-return-modal__shipping-method"
        type="secondary"
        :activated="shippingButtonLoading"
        @click="handleUpdateShippingMethod"
      >
        Return shipping method
      </base-button>

      <base-button
        v-if="props.editOptions.refundMethod"
        class="edit-return-modal__refund-method"
        type="secondary"
        :activated="refundButtonLoading"
        @click="handleUpdateRefundMethod"
      >
        Refund option
      </base-button>

      <base-button
        v-if="props.editOptions.exchanges"
        class="edit-return-modal__exchange-item"
        type="secondary"
        :activated="exchangeButtonLoading"
        @click="handleUpdateExchangeItem"
      >
        Exchange items
      </base-button>

      <base-button
        v-if="props.editOptions.cancel"
        class="edit-return-modal__cancel-return"
        :activated="cancelButtonLoading"
        @click="handleCancelReturn"
      >
        <span class="edit-return-modal__cancel-return-text">
          <base-text
            class="edit-return-modal__heading"
            tag="p"
            type="heading"
          >
            Cancel your return
          </base-text>
          <base-text
            class="edit-return-modal____cancel-return-subheading"
            tag="p"
            type="body-2"
          >
            Your return will be canceled. You'll be able to create a new return for your items if needed.
          </base-text>
        </span>
      </base-button>
      <customer-information
        v-if="showEditCustomerInfoModal"
        :customer="props.item.customer"
        :return-id="props.item.id"
        :address="props.item.address"
        :can-edit="props.editOptions.address"
        :is-modal-active="true"
        class="edit-return-modal__customer-information"
        :errors="labelAddressErrors"
        @close="handleCloseCustomerInformation"
        @update="updateCustomerAddress"
      />
      <edit-exchange-items-modal
        v-if="showEditExchangeItemsModal"
        :exchange-items="props.item.exchanges"
        :variants="props.variants"
        :return-key="props.item.returnKey"
        @close="handleEditExchangeItemsModalClose"
      />
      <edit-refund-method-modal
        v-if="showEditRefundMethodModal"
        :return-key="props.item.returnKey"
        :available="availableRefundMethods"
        :current-refund-type="currentRefundType"
        @close="handleEditRefundMethodModalClose"
      />
      <edit-return-shipping-method-modal
        v-if="showEditShippingMethodModal"
        :is-modal-active="showEditShippingMethodModal"
        :return-key="props.item.returnKey"
        :return-method-options="shippingMethodOptions"
        @returnMethodSelected="newReturnMethodSelected"
        @close="handleCloseShippingMethodModal"
      />
      <edit-return-confirmation-modal
        v-if="showConfirmationModal"
        :is-modal-active="showConfirmationModal"
        :heading="confirmationModalHeading"
        :message="confirmationModalMessage"
        :confirm-button-text="confirmationModalButtonText"
        :is-destroy="confirmationModalDestroy"
        @close="handleCloseConfirmationModal"
        @confirmed="handleActionConfirmed"
        @denied="handleActionDenied"
      />
    </div>
  </modal-dialog>
</template>

<script setup>
import {
  BaseButton,
  BaseText
} from '@loophq/design-system';
import { ref, computed, reactive, onMounted } from 'vue';
import ModalDialog from '@/components/layout/Modal';
import Returns from '@/js/controllers/return';
import useLabels from '@/js/composables/views/StatusPage/labels';
import CustomerInformation from '@/views/StatusPage/CustomerInformation';
import EditExchangeItemsModal from '@/views/StatusPage/EditExchangeItemsModal';
import EditRefundMethodModal from '@/views/StatusPage/EditRefundMethodModal';
import EditReturnShippingMethodModal from '@/views/StatusPage/EditReturnShippingMethodModal';
import EditReturnConfirmationModal from '@/views/StatusPage/EditReturnConfirmationModal.vue';
import { enablePageScroll } from 'scroll-lock';
import { returnCreditTypes } from '@/js/constants/returns';

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  variants: {
    type: Object,
    required: true
  },
  editOptions: {
    type: [Object, null],
    required: true
  },
});

const emit = defineEmits(['update', 'close', 'cancel']);

const active = ref(true);
const selectedAction = ref(null);

const cancelButtonLoading = ref(false);
const addressButtonLoading = ref(false);
const shippingButtonLoading = ref(false);
const refundButtonLoading = ref(false);
const exchangeButtonLoading = ref(false);
const showEditCustomerInfoModal = ref(false);
const showEditExchangeItemsModal = ref(false);
const showEditRefundMethodModal = ref(false);
// to do: pull refund info from return policy to update this
const availableRefundMethods = reactive({
  gift: true,
  refund: true
});
const showEditShippingMethodModal = ref(false);
const showConfirmationModal = ref(false);

const confirmationModalHeading = ref('');
const confirmationModalMessage = ref('');
const confirmationModalButtonText = ref('I’m done. View my return.');
const confirmationModalDestroy = ref(false);

const shippingMethodOptions = ref([]);

const loading = ref('page');

const {
  labelAddressErrors,
} = useLabels(props.item, loading);

const isActive = computed(() => {
  return active.value;
});

const currentRefundType = computed(() => {
  if (props.item.hasRefund) {
    return returnCreditTypes.REFUND;
  }

  if (props.item.hasGift) {
    return returnCreditTypes.GIFT;
  }

  return null;
});

const closeModal = (refreshPage = false) => {
  selectedAction.value = null;
  active.value = false;
  emit('close', refreshPage);
};

const handleUpdateAddress = () => {
  addressButtonLoading.value = true;
  showEditCustomerInfoModal.value = true;
  addressButtonLoading.value = false;
};

const handleCancelReturn = () => {
  selectedAction.value = 'cancel';
  cancelButtonLoading.value = true;

  showConfirmationModal.value = true;
  confirmationModalHeading.value = 'Are you sure you want to cancel your return?';
  confirmationModalMessage.value = 'Selecting this option will immediately cancel this return. If applicable, your return label will be voided. You will be able to create another return for these items in the future if necessary if your order is still in the return window.';
  confirmationModalButtonText.value = 'Yes, cancel my return';
  confirmationModalDestroy.value = true;

  cancelButtonLoading.value = false;
};

const updateCustomerAddress = ({ address }) => {
  emit('update', { address });
};

const handleUpdateShippingMethod = () => {
  shippingButtonLoading.value = true;
  showEditShippingMethodModal.value = true;
  shippingButtonLoading.value = false;
};

const handleUpdateRefundMethod = () => {
  refundButtonLoading.value = true;
  showEditRefundMethodModal.value = true;
  refundButtonLoading.value = false;
};

const handleUpdateExchangeItem = () => {
  exchangeButtonLoading.value = true;
  showEditExchangeItemsModal.value = true;
  exchangeButtonLoading.value = false;
};

const handleCloseCustomerInformation = (refreshPage = false) => {
  showEditCustomerInfoModal.value = false;
  enablePageScroll();
  if (refreshPage) {
    closeModal(true);
  }
};

const handleEditExchangeItemsModalClose = (refreshPage = false) => {
  showEditExchangeItemsModal.value = false;
  closeModal(refreshPage);
};

const handleEditRefundMethodModalClose = (refreshPage = false) => {
  showEditRefundMethodModal.value = false;
  closeModal(refreshPage);
};

const newReturnMethodSelected = (newReturnMethod) => {
  shippingMethodOptions.value.forEach(method => {
    if (method.id === newReturnMethod.id) {
      method.selected = true;
    } else {
      method.selected = false;
    }
  });
};

const handleCloseShippingMethodModal = (newReturnMethod) => {
  showEditShippingMethodModal.value = false;

  if (newReturnMethod) {
    showConfirmationModal.value = true;
    confirmationModalHeading.value = 'Return shipping method updated successfully';
    confirmationModalMessage.value = 'The return shipping method has been updated to ' + newReturnMethod.display + '.';
  } else {
    emit('close');
  }
};

const handleCloseConfirmationModal = () => {
  showConfirmationModal.value = false;
  closeModal();
};

const handleActionConfirmed = async () => {
  if (selectedAction.value === 'cancel') {
    await Returns.cancelReturn(props.item.returnKey);
  }

  closeConfirmationModal();
  closeModal(true);
};

const handleActionDenied = () => {
  closeConfirmationModal();
};

const closeConfirmationModal = () => {
  confirmationModalHeading.value = '';
  confirmationModalMessage.value = '';
  confirmationModalButtonText.value = 'I’m done. View my return.';
  confirmationModalDestroy.value = false;
  showConfirmationModal.value = false;
};

onMounted(() => {
  // TODO: Here we would fetch shipping method options from the backend
  shippingMethodOptions.value = [
    {
      id: 1,
      name: 'drop-off',
      display: 'Happy Returns',
      image: '/img/integrations/first-mile/happy-returns.svg',
      selected: false,
    },
    {
      id: 2,
      name: 'box-and-ship',
      display: 'Prepaid Shipping Label',
      image: '/img/integrations/first-mile/ship.svg',
      selected: false,
    }
  ];

  const returnHasLabel = props.item.label != undefined && props.item.label != null;
  const returnHasShippingMethod = props.item.returnMethod != undefined && props.item.returnMethod != null;

  if (returnHasLabel) {
    shippingMethodOptions.value.forEach((method) => {
      if (method.name === 'box-and-ship') {
        method.selected = true;
      }
    });
    return;
  }

  if (returnHasShippingMethod) {
    shippingMethodOptions.value.forEach((method) => {
      if (method.name === 'drop-off') {
        method.selected = true;
      }
    });
  }
});
</script>

  <style lang="scss" scoped>
    $block: '.edit-return-modal';
    #{$block} {
      &__cancel-return {
        // Since the frontend doesn't currently have a destructive button type, override the
        // primary color here for now
        &.primary {
          background: var(--color-red-600);
          color: white;
        }

        // to do: button text is not mobile friendly
        &-text {
          display: flex;
          flex-direction: column;
          align-items: center;

          :deep(p) {
            text-align: center;
            margin-top: var(--spacing-200);
            color: white;
          }
       }
      }

      &__action-container {
        margin-top: var(--spacing-400);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-400);
        height: 75vh;
      }
    }
  </style>

